import { Form, useNavigation } from "@remix-run/react";
import { H1 } from "./Text";
import { Button } from "./Button";
import { StarIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";

const LovedBy = () => (
  <div className="m-auto text-slate-200 flex flex-col gap-2">
    <div className="flex flex-row gap-2 opacity-75 m-auto">
      <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
      <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
      <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
      <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
      <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
    </div>
    <p>Loved by 1,000+ developers in Europe 🇪🇺</p>
  </div>
);

export const HeaderCTA = ({
  location = "Europe",
  title,
}: {
  location?: string;
  title?: ReactNode;
}) => {
  const nav = useNavigation();

  return (
    <div className="text-center -mt-8 h-[calc(100svh-64px-2rem)] md:h-fit md:my-24 flex flex-col justify-center items-center">
      <div className="max-w-2xl mx-auto  my-auto">
        <H1 noMargin center>
          {title || (
            <>
              Find dev jobs that pay{" "}
              <span className="decoration-lime-400 underline">€100k+</span> in{" "}
              <i>{location}</i>
            </>
          )}
        </H1>
        <p className="my-6 text-md md:text-lg m-1 leading-8 text-gray-300">
          Get notified about Software Engineering jobs at{" "}
          <span className="text-lime-400 italic">top tech companies</span>.
        </p>
        <Form
          method="post"
          className="mt-10 m-auto flex flex-wrap gap-5 text-md lg:text-lg gap-x-6 plausible-event-name=Signup"
        >
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            className="bg-gray-800 border flex-grow border-gray-700 rounded-md px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
            required
          />
          <span className="hidden">Don't fill this field</span>
            <input
              type="text"
            name="username"
            className="hidden"
            tabIndex={-1}
            autoComplete="off"
          />
          <Button
            loading={nav.state === "submitting"}
            size="lg"
            disabled={nav.state === "submitting"}
            type="submit"
            className="w-full mx-auto sm:w-auto"
          >
            Sign up
          </Button>
        </Form>
        <p className="mt-3 text-gray-500">No spam. Unsubscribe anytime.</p>
        <div className="mt-10">
          <LovedBy />
        </div>
      </div>
    </div>
  );
};
